<template>
  <v-container class="background" fluid fill-height>
    <!-- <v-layout  justify-center align-center> -->
    <v-layout  justify-center align-center>
      <v-flex xs12 sm8 md2>

        <!-- <v-card-title
        class="text-bold px-0 title-system"
        >
            BAKUN HEP CONTINUOUS MONITORING STATIONS
        </v-card-title> -->


        <v-card width="400px" class="background-cardLogin">
          <v-card-title class="justify-center pt-10 primary--text">
            <!-- Login -->
            <v-img
              class="mx-2 mb-2 mouse-pointer"
              src="@/assets/sarawak-energy-logo-shadow2.png"
              max-height="100"
              max-width="180"
              contain
            ></v-img>
            <!-- <v-card-title>
                    Bakun HEP <br> Continuous Monitoring Stations
                    </v-card-title> -->
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="onSignin">
                <v-row>
                  <v-col xs12 class="pb-0">
                    <v-text-field
                      dense
                      id="email"
                      class="field-login"
                      name="Math.random()"
                      label="Email"
                      v-model="email"
                      type="email"
                      solo
                      required
                      autocomplete="email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs12 class="pt-0 pb-0">
                    <v-text-field
                      dense
                      color="primary"
                      id="password"
                      class="field-login"
                      name="Math.random()"
                      label="Password"
                      v-model="password"
                      type="password"
                      solo
                      required
                      autocomplete="new-password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <span class="pl-4 primary--text" @click="openModal"
                    >Forgot Password?</span
                  >
                </v-row>
                <v-row>
                  <v-dialog v-model="forgotDialog" width="350" height="400">
                    <v-card>
                      <v-col class="text-center pt-10">
                        <v-icon large color="primary">mdi-alert-circle</v-icon>
                      </v-col>
                      <v-card-title class="justify-center primary--text"
                        >Forgot Password
                      </v-card-title>
                      <v-card-text>
                        <v-col class="text-center primary--text">
                          <span
                            >Enter your email and we'll send you a link reset
                            your password.</span
                          >
                        </v-col>
                        <v-col class="pb-0 pt-5">
                          <v-text-field
                            v-model="email"
                            dense
                            outlined
                            label="Email"
                          ></v-text-field>
                        </v-col>
                        <v-col class="text-center pt-0">
                          <v-checkbox
                            dense
                            class="align-center text-xs-center"
                            v-model="captchaBox"
                            label="I'm not a robot"
                          ></v-checkbox>
                        </v-col>
                        <v-col v-show="captchaBox == true">
                          <v-img :src="captchaPic"></v-img>
                        </v-col>
                        <v-col v-show="captchaBox == true">
                          <v-btn
                            @click="getCaptcha"
                            width="100%"
                            class="primary--text"
                            ><v-icon>mdi-reload</v-icon></v-btn
                          >
                        </v-col>
                        <v-col v-show="captchaBox == true">
                          <v-text-field
                            v-model="captchaWord"
                            dense
                            outlined
                            label="Enter Captcha"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pt-0" v-show="captchaBox == true">
                          <v-btn
                            width="100%"
                            color="primary"
                            type="Submit"
                            @click="validateCaptcha"
                            :loading="loading"
                          >
                            Submit
                          </v-btn>
                        </v-col>

                        <v-col class="pt-0" v-show="captchaBox == true">
                          <v-alert
                            type="error"
                            dense
                            v-if="errorAlert"
                            :value="true"
                            color="error"
                            icon="mdi-alert"
                            transition="scale-transition"
                            dismissible
                            >{{ errorMsg }}
                          </v-alert>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      class="primary"
                      block
                      dark
                      type="submit"
                      :loading="loading"
                      >Login</v-btn
                    >
                  </v-col>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
// import VueRecaptcha from "vue-recaptcha";
import axios from "axios";

export default {
  // components: VueRecaptcha,
  data() {
    return {
      email: "",
      password: "",
      showAlert: false,
      forgotDialog: false,
      siteKey: "",
      captchaPic: "",
      captchaWord: "",
      captchaBox: false,
      showCheckbox: false,
      showTextField: false,
      errorAlert: false,
      errorMsg: "",
      successMsg: "",
    };
  },
  methods: {
    getCaptcha() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/users/captcha`, {
          headers: {
            Authorization: "Bearer " + null,
          },
        })
        .then((response) => {
          this.siteKey = response.data.id;
          this.captchaPic = response.data.captchablob;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validateCaptcha() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/users/resetpass?emel=${this.email}&kapchaword=${this.captchaWord}&kapchaid=${this.siteKey}`,
          {
            headers: {
              Authorization: "Bearer " + null,
            },
          }
        )
        .then((response) => {
          this.successMsg = response.data;
          console.log(this.successMsg);
          this.close();
        })
        .catch((error) => {
          this.errorMsg = "Your email doesn't exist in our record";
          this.errorAlert = true;
          console.log(error);
        });
    },

    openModal() {
      this.forgotDialog = true;
    },

    close() {
      this.forgotDialog = false;
    },

    onSignin() {
      this.$store.dispatch("signUserIn", {
        email: this.email,
        password: this.password,
      });
    },

    // onDismissAlert() {
    //   this.$store.dispatch("clearError");
    // },
  },
  computed: {
    // user() {
    //   return this.$store.getters.user;
    // },
    loading() {
      return this.$store.getters.loading;
    },
    error() {
      return this.$store.getters.error;
    },
    // token() {
    //   return this.$store.getters.user.token;
    // },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        // this.$router.push('/')
      }
    },

    forgotDialog: function (val) {
      if (val) {
        this.password = "";
        this.email = "";
        this.captchaBox = false;
        this.captchaWord = "";
      }
    },
  },

  mounted() {
    this.getCaptcha();
  },
};
</script>

<style lang="scss" scoped>
.background {
  // background-image: url('~@/assets/Bakun-HEP.jpg') !important;
  // background-image: url("~@/assets/Bakun-HEP-7.jpg") !important;
  background-image: url("~@/assets/Bakun-HEP-10.jpg") !important;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.background-cardLogin {
  background-image: linear-gradient(to bottom, #0199be, #ffffff) !important;
  float: left !important;
}

.title-system{
    word-break:break-word;
    font-size:1rem;
    font-weight: bold;
    text-align:center !important;
    text-shadow: 1px 1px 1px black;
    color: white !important;
    /* text-shadow: 0px 1px black; */
}
</style>